<template>
  <BaseModal class="base-overlay-first main-base-modal-first">
    <div class="modal">
      <img
        alt=""
        class="header__logo"
        height="20"
        src="/logos/logo.svg"
        width="102"
      />

      <div class="modal__container">
        <p class="modal__title typography__headline3">Создание нового пароля</p>

        <form
          class="modal__container"
          @submit="(event) => handleSubmit(event)"
        >
          <div class="modal__wrapper">
            <!-- TODO: Заменить на BaseInputPassword -->
            <BaseInput2
              v-model="fields.password[0].value"
              v-bind="fields.password[1].value"
              autocomplete="password"
              class="base-input-first"
              :error="errors.password"
              name="password"
              placeholder="Новый пароль"
              type="password"
            />

            <!-- TODO: Заменить на BaseInputPassword -->
            <BaseInput2
              v-model="fields.password_confirmed[0].value"
              v-bind="fields.password_confirmed[1].value"
              autocomplete="password"
              class="base-input-first"
              :error="errors.password_confirmed"
              name="password_confirmed"
              placeholder="Повторите новый пароль"
              type="password"
            />
          </div>

          <div class="modal__buttons">
            <BaseButton2
              class="base-button-extra-big base-button-first base-button-round"
              class-loader="base-loader-second"
              :loading
              type="submit"
            >
              Сохранить
            </BaseButton2>

            <BaseButton2
              class="base-button-extra-big base-button-round base-button-second"
              :disabled="loading"
              @click="() => closeModal()"
            >
              Отмена
            </BaseButton2>
          </div>
        </form>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
const { errors, fields, handleSubmit, loading } = useAuthChangePasswordForm();
const { closeModal } = useModalStore();
</script>

<style scoped>
.modal {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .modal {
    row-gap: 40px;
  }
}

.modal__title {
  color: var(--grey-1-5);
}

.modal__container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.modal__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.modal__buttons {
  display: flex;
  column-gap: 16px;
}
</style>
